<template>
    <div class="effect">
        <div class="title">
            <div class="sub-title">招聘效果</div>
        </div>
        <el-card class="box-card">
            <div class="yestoday">
                <div class="title flex">
                    <div class="title-left">历史数据</div>
                    <div class="title-right">数据更新至：{{numbers.yestoday}}</div>
                </div>
                <div class="summary flex">
                    <div class="summary-item flex border">
                        <span class="">收藏简历量</span>
                        <em>{{numbers.resumeNumber}}</em>
                    </div>
                    <div class="summary-item flex border">
                        <span class="">获得简历量</span>
                        <em>{{numbers.deliveryNumber }}</em>
                    </div>
                    <div class="summary-item flex">
                        <span class="">邀约量</span>
                        <em>{{numbers.interviewNumber}}</em>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div class="summary-tab">
                <div class="tab-item" :class="[flag===1?'selected':'']" @click="changeFlag(1)">收到的简历</div>
                <div class="tab-item" :class="[flag===2?'selected':'']" @click="changeFlag(2)">收藏的简历</div>
            </div>
            <div class="charts">
                <div id="browse-chart" v-show="flag===1" style="width:100%; height:500px;"></div>
                <div id="resume-chart" v-show="flag===2" style="width:100%; height:500px;"></div>
            </div>
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
export default {
    mounted() {
        this.getNumbers()
        this.getEchartsData()
        // this.setBrowseChart()
        // this.setResumeChart()
    },
    data() {
        return {
            flag: 1,
            numbers: {},
            broseOptions: {
                color: ["#ff704f"],
                tooltip: {
                    trigger: "axis",
                    formatter: (params) => {
                        // console.log('params', params)
                        return `${params[0].name} <br /> ${params[0].marker}获得简历: ${params[0].data}`
                    }
                },
                grid: {
                    left: "0%",
                    top: "10px",
                    right: "5%",
                    bottom: "4%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    boundaryGap: false,
                    axisLabel: {
                        textStyle: {
                            color: "#bbb",
                            fontSize: 12
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#bbb"
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: "#bbb"
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#bbb",
                            fontSize: 12
                        }
                    },
                    // 修改分割线的颜色
                    splitLine: {
                        lineStyle: {
                            color: "#fff"
                        }
                    }
                },
                series: [{
                    smooth: true,
                    type: 'line',
                    lineStyle: {
                        width: "2"
                    },
                    data: [],
                    symbol: "circle",
                    symbolSize: 4,
                    showSymbol: false,
                    itemStyle: {
                        color: "#ff704f",
                        borderColor: "rgba(0, 0, 0, .1)",
                        borderWidth: 8
                    },
                    areaStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                offset: 0,
                                color: "rgba(255, 112, 79, 0.16)"
                                },
                                {
                                offset: 0.8,
                                color: "rgba(255, 112, 79, 0.08)"
                                }
                            ],
                            false
                            ),
                            shadowColor: "rgba(0, 0, 0, 0.1)"
                        }
                    },
                    
                }]
            },
            resumeOptions: {
                color: ["#ff704f"],
                tooltip: {
                    trigger: "axis",
                    // formatter: (params) => {
                    //     console.log('params', params)
                    //     return `${params[0].name}浏览量: <br /> ${params[0].data}`
                    // }
                    formatter: (params) => {
                        // console.log('params', params)
                        return `${params[0].name} <br /> ${params[0].marker}收藏简历: ${params[0].data}`
                    }
                },
                grid: {
                    left: "0%",
                    top: "10px",
                    right: "5%",
                    bottom: "4%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['11.09', '11.10', '11.11', '11.12', '11.13', '11.14', '11.15'],
                    boundaryGap: false,
                    axisLabel: {
                        textStyle: {
                            color: "#bbb",
                            fontSize: 12
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#bbb"
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: { show: false },
                    axisLine: {
                        lineStyle: {
                            color: "#bbb"
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#bbb",
                            fontSize: 12
                        }
                    },
                    // 修改分割线的颜色
                    splitLine: {
                        lineStyle: {
                            color: "#fff"
                        }
                    }
                },
                series: [{
                    smooth: true,
                    type: 'line',
                    lineStyle: {
                        width: "2"
                    },
                    data: [952, 3254, 2456, 1236, 7569, 1369, 8920],
                    symbol: "circle",
                    symbolSize: 4,
                    showSymbol: false,
                    itemStyle: {
                        color: "#ff704f",
                        borderColor: "rgba(0, 0, 0, .1)",
                        borderWidth: 8
                    },
                    areaStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                offset: 0,
                                color: "rgba(255, 112, 79, 0.16)"
                                },
                                {
                                offset: 0.8,
                                color: "rgba(255, 112, 79, 0.08)"
                                }
                            ],
                            false
                            ),
                            shadowColor: "rgba(0, 0, 0, 0.1)"
                        }
                    },
                    
                }]
            }
        }
    },
    methods: {
        changeFlag(row) {
             this.flag = row
             this.getEchartsData()
            //  if (this.flag ===1) {
            //     //  this.setBrowseChart()
            //  } else if (this.flag ===2) {
            //      this.setResumeChart()
            //  }
        },
        setBrowseChart () {
            this.$nextTick(() => {
                let broseChart = this.$echarts.init(document.getElementById("browse-chart"))
                broseChart.setOption(this.broseOptions)
            })
        },
        setResumeChart () {
            this.$nextTick(() => {
                let resumeChart = this.$echarts.init(document.getElementById("resume-chart"))
                resumeChart.setOption(this.resumeOptions)
            })
            
        },
        //获取历史数据信息
        getNumbers() {
            companyRequest.getNumbers().then((res) => {
                if (res.code === 200) {
                    this.numbers = res.data
                    this.numbers.yestoday = this.getDay()
                }
            }).catch((err) => {
                this.numbers.resumeNumber = 0
                this.numbers.deliveryNumber = 0
                this.numbers.interviewNumber  = 0
                this.numbers.yestoday = this.getDay()
            })
        },
        //图表数据
        getEchartsData() {
            companyRequest.getEchartsData({type: this.flag}).then((res) => {
                if (res.code === 200) {
                    let data = res.data
                    console.log('data', data)
                    // let keys = []
                    // let week = []
                    // let weekDays = []
                    // let values = []
                    // let key
                    // for(key in data) {
                    //     // console.log('key', key)
                    //     keys.push(key.split('-')[2])
                    //     week.push(key)
                    // }
                    // // console.log('keys1', keys)
                    // keys.sort((a, b) => {
                    //     return a - b
                    // })
                    // // console.log('keys', keys)
                    // // console.log('week', week)
                    // keys.forEach((item) => {
                    //     const index = week.findIndex((row) => {
                    //         return row.indexOf(item) != -1
                    //     })
                    //     // console.log('index', index)
                    //     weekDays.push(week[index])
                    // })
                    // console.log('weekDays', weekDays)
                    // weekDays.forEach((item) => {
                    //    values.push(data[item]) 
                    // })
                    //  console.log('values', values)
                    // keys.forEach((item) => {
                    //     week.push(this.getDay(new Date(item)))
                    // })
                    // console.log('keys2', keys)
                    // console.log('week', week)
                    let weekDays = []
                    let values = []
                    let nowDay = new Date().getTime()
                    console.log('nowDay', nowDay)
                    for (let i = 0; i <Object.keys(data).length; i++) {
                        let number = nowDay - i * 24 * 60 * 60 * 1000
                        let nemberDay = new Date (number)
                        let year = nemberDay.getFullYear()
                        let month = nemberDay.getMonth() + 1 
                        let day = nemberDay.getDate()
                        month < 10 ? `0${month}` : month
                        day < 10 ? `0${day}` : day
                        weekDays.unshift(`${year}-${month}-${day}`)
                    }
                    console.log('weekDays', weekDays)
                    weekDays.forEach((item, index) => {
                        values.push(data[item] ? data[item] : 0)
                    })
                    console.log('values', values)
                    if(this.flag === 1) {
                        this.broseOptions.xAxis.data = weekDays
                        this.broseOptions.series[0].data = values
                        this.setBrowseChart()
                    } else if (this.flag === 2) {
                        this.resumeOptions.xAxis.data = weekDays
                        this.resumeOptions.series[0].data = values
                        this.setResumeChart()
                    }
                }
            })
        },
        getDay() {
            // let nowDate = new Date().getTime()
            // let yestoday = nowDate - (60 * 60 * 24 * 1000)
            // let yestodayDate = new Date(yestoday)
            let nowDate = new Date()
            let year = nowDate.getFullYear()
            let month = nowDate.getMonth() + 1 
            let day = nowDate.getDate()
            month < 10 ? `0${month}` : month
            day < 10 ? `0${day}` : day
            return `${year}-${month}-${day}`
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.effect{
    height: 100%;
    .el-card{
        padding-bottom: 20px;
    }
    .yestoday{
        .title{
            padding: 0 20px;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            border-bottom: 1px solid #eee;
            .title-left{
                color: #111;
                font-weight: 700;
            }
            .title-right{

            }
        }
        .summary{
            .summary-item{
                width: 33.333%;
                justify-content: center;
                // padding: 30px 0;
                &.border{
                    border-right: 1px solid #f3f3f3;
                }
                span{
                    position: relative;
                    top: 6px;
                    text-align: center;
                    line-height: 84px;
                    font-size: 14px;
                    color: #333;
                }
                em{
                    text-align: center;
                    line-height: 84px;
                    font-size: 36px;
                    font-style: normal;
                    color: #383d4a;
                    font-weight: 700;
                    margin-left: 7px;
                }
            }
        }
    }
    .summary-tab{
            border-bottom: 2px solid #f0f0f0;
            font-size: 0;
            margin-bottom: 21px;
            .tab-item{
                display: inline-block;
                padding: 0 0 8px;
                border-bottom: 2px solid transparent;
                margin-right: 40px;
                margin-bottom: -2px;
                font-size: 16px;
                line-height: 16px;
                color: #222;
                cursor: pointer;
                &.selected{
                    color: #ff704f;
                    border-bottom: 2px solid #ff704f;
                }
            }
        }
}
</style>